// TODO: change this to MaybeServer after all components using DisplayOffer bundle migrate to app folder:
import * as Maybe from '@components/layout/Maybe'
import BreadcrumbBar from '@components/breadcrumbs/BreadcrumbBarNew'
import {
	HiddenNumbers,
	MobileSticky as StickyContact,
	WriteMessageButton,
} from '@components/displayNew/DisplayContact'

function DisplayOfferBreadcrumbContactFragment({
	offer,
	user,
	buttons = false,

	...rest
}) {
	return (
		<>
			<Maybe.Desktop>
				<BreadcrumbBar locationPosition={3} {...rest}>
					{buttons && (
						<ContactButtons offer={offer} recipient={user} />
					)}
				</BreadcrumbBar>
			</Maybe.Desktop>
			<Maybe.Mobile>
				<StickyContact offer={offer} recipient={user} />
			</Maybe.Mobile>
		</>
	)
}

function ContactButtons({ offer, recipient }) {
	return (
		<>
			<WriteMessageButton
				offer={offer}
				recipient={recipient}
			/>
			<HiddenNumbers
				offer={offer}
				recipient={recipient}
			/>
		</>
	)
}

export default DisplayOfferBreadcrumbContactFragment
export { ContactButtons }
