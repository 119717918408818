import ensureArray from '@utils/array/ensureArray'

const userPhone = (user, single = true, offer) => {
    const { contact = {}, dealer = {}} = user || {}
    const _dealerPhones = dealerPhones(dealer)
    const _userPhone = [offer?.tracking?.phone, contact?.phone]
    const phones = [..._dealerPhones, ..._userPhone].filter(isPhone)

    return single ? phones[0] : [...new Set(phones)]
}

function dealerPhones (dealer) {
    if (typeof dealer !== 'object') return []
    const { firm_phone, firm_phone_2, firm_phone_3 } = dealer
    const phones = [...ensureArray(firm_phone), firm_phone_2, firm_phone_3].filter(isPhone)
    if (!phones.length) return []
    return phones
}

function isPhone (string) {
    return typeof string === 'string' && Boolean(string.trim())
}

export default userPhone
export {
    dealerPhones,
}