function generationFor ({ generation }) {
    return generation && generation != '-'
        ? ` ${sliceOff(generation)}`
        : ''
}

function sliceOff (generation) {
    const idx = generation.indexOf('(')
    return idx !== -1
        ? generation.slice(0, idx)
        : generation
}

export default generationFor