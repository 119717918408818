"use client"

import { createContext, useContext, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import { useSearchParamsAsQuery } from '@hooks/useSearchParamsAsQuery'
import Link from 'next/link'
import useT from '@hooks/useTranslation'

import styles from 'styles/Breadcrumbs.module.css'

const BreadcrumbContext = createContext({})
const useBreadcrumnContext = () => useContext(BreadcrumbContext)

const SKIP_ALLWAYS = ['[shortid]', '[lang]', 'lokalizacja', 'oferta']
const NAME_MAP = {'czesci':'części'}

const Breadcrumbs = ({
    chunkNameMappings = NAME_MAP,
    cutAtLocation = true,
    currentReplaceChunks,
    explicit,
    host,
    locationPosition,
    skip = [],
}) => {
    const pathname = usePathname()
    const query = useSearchParamsAsQuery()
    const chunks = pathChunks({ pathname, query }, skip, locationPosition, cutAtLocation, currentReplaceChunks)
    const fullpath = usePathname()

    const handyValues = useMemo(() => ({
        chunkNameMappings,
        chunks,
        cutAtLocation,
        explicit,
        fullpath,
        host,
    }), [chunkNameMappings, chunks, cutAtLocation, explicit, fullpath, host])

    return (
        <BreadcrumbContext.Provider value={handyValues} >
            <CrumbsWrapper>
                <Crumbs
                    chunks={chunks}
                    cutAtLocation={cutAtLocation}
                    explicit={explicit}
                    fullpath={fullpath}
                    host={host}
                />
            </CrumbsWrapper>
        </BreadcrumbContext.Provider>
    )
}

function CrumbsWrapper ({ children }) {
    return (
        <ol className={styles.wrapper} itemScope itemType="http://schema.org/BreadCrumbList">
            { children }
        </ol>
    )
}

function Crumbs () {
    const { chunks, cutAtLocation, explicit, fullpath, host } = useBreadcrumnContext()

    const usableChunks = explicit || [{ name: hostName(host), path: hostPath(host) }, ...chunks]

    const usableCount = usableChunks.length - 1

    return usableChunks
        .reduce((acc, chunk, i) => {
            const {name, path} = chunk
            acc.push(
                <Crumb
                    key={path || i}
                    chunk={name}
                    path={(i === usableCount && !cutAtLocation)
                        ? fullpath
                        : path}
                    pos={i}
                />
            )

            return acc
        }, [])
}

function Crumb ({ chunk, path, pos }) {
    const { chunkNameMappings } = useBreadcrumnContext()

    return (
        <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" className={styles.crumb}>
            <Link href={path || '#'} itemProp="item">

                <span itemProp="name">{unslugify(chunk, chunkNameMappings)}</span>

            </Link>
            <meta itemProp="position" content={pos+1} />
        </li>
    );
}

function pathChunks ({ pathname, query }, skip, locationPosition, cutAtLocation, currentReplaceChunks) {
    const currentPath = []
    const chunks = pathname.slice(1).split('/')
    const usableChunks = cutAtLocation
        ? chunks.slice(0, locationPosition)
        : chunks

    const final = usableChunks.map((chunk, i) => {
            if (chunk === 'lokalizacja') locationPosition = i + 1
            if (skip.includes(chunk) || SKIP_ALLWAYS.includes(chunk) || chunk == null) return null

            const name = chunk.startsWith('[') && chunk.endsWith(']')
                ? query[chunk.slice(1, -1)]
                : chunk

            let pathPrefix = ''
            if (locationPosition === i) {
                pathPrefix = 'lokalizacja/'
            }

            currentPath.push(`${pathPrefix}${name}`)

            return {
                name,
                path: `/${currentPath.join('/')}`,
            }
        }).filter(Boolean)

    return currentReplaceChunks
        ? final.slice(0, -1).concat(currentReplaceChunks)
        : final
}

function unslugify (string, map = {}) {
    if (string == null) return ''
    if (string == '/') return useT('Strona główna')
    const unslugified = decodeURIComponent(string.replace(/-/g, ' '))
    return map
        ? useT(map[unslugified] || unslugified)
        : useT(unslugified)
}

function hostName (host) {
    return Array.isArray(host) && host[0]
        ? useT(host[0])
        : '/'
}

function hostPath (host) {
    return Array.isArray(host) && host[1]
        ? host[1]
        : '/'
}

export default Breadcrumbs