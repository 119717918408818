"use client"

import Container from '@components/layout/Container'
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs'
import Icon from '@components/shared/Icon'

import styles from './BreadcrumbBarNew.module.css'

const BreadcrumbBar = ({ children, style, ...rest }) => {

    return <div className={styles.bar}>
        <Container>
            <div className={styles['bar-inner']}>
                <div className={styles.breadcrumbs}>
                    <span className={styles.back} onClick={()=>window.history.back()}><Icon>arrow_back_ios_new</Icon></span>
                    <Breadcrumbs {...rest} />
                </div>

                <div className={styles.content} style={style}>
                    { children }
                </div>
            </div>
        </Container>
    </div>
}

export default BreadcrumbBar