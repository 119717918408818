"use client"

import Button from '@components/button/Button'
import Icon from '@components/shared/Icon'
import onClickScrollToId from '@utils/dom/onClickScrollToId'

const scrollToMap = () => {
    const mapEl = document.getElementById('osm-map')
    if (mapEl) mapEl.scrollIntoView({ behavior: 'smooth' })
}

const MapIcon = ({ address }) => (
    <span icon="true" onClick={scrollToMap} role="button">
		<Icon>location_on</Icon> {address}
	</span>
)

const ScrollToOfferButton = ({ scrollId, text, ...rest }) => (
    <Button {...rest} onClick={onClickScrollToId(scrollId)}>
		{text}
	</Button>
)

export {
    MapIcon,
    ScrollToOfferButton,
}