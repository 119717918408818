import Wrapper from '@components/image/Wrapper'
import AdvancedImage from '@components/image/WithFallback'
import salonLogo from '@utils/links/salonLogo'

const logoFallback = '/fallback/logo-salon-samochodowy.png'

export default function Logo ({ _id, alt, has_logo, ...rest }) {
    return (
        <Wrapper {...rest}>
            <AdvancedImage
                src={has_logo
                    ? salonLogo(_id)
                    : logoFallback}
                alt={alt}
                fallback={logoFallback}
                layout="fill"
                objectFit="contain"
            />
        </Wrapper>
    )
}