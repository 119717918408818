import Spinner from './Spinner'

import styles from 'styles/SpinnerBlock.module.css'

const centerStyle = {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
}

const SpinnerBlock = ({ height = '100%', style, ...rest }) => (
    <div className={styles.block} style={style || { height }}>
        <Spinner {...rest} />
    </div>
)

SpinnerBlock.center = centerStyle

export default SpinnerBlock