"use client"

import { useCallback, useState } from 'react'
import Container from '@components/layout/Container'
import Button from '@components/button/Button'
import DisplaySticky from './DisplaySticky'
import ContactForm from '@components/display/user/DynamicContactForm'
import MessageHelper from '@utils/messageHelper'
import userPhone from '@utils/user/userPhone'
import offerName from '@utils/infoPresentation/offerName'
import useRedirect from '@hooks/useRedirectBack'
import useT from '@hooks/useTranslation'
import mix from '@utils/styles/mix'

import { LOGIN_PAGE } from '@constants/routes'

import withUser from '@hoc/withUser'
import useViewCallback from '@components/display/user/useViewCallback'

import styles from '@components/button/Button.module.css'

const DisplayContact = {}

function HiddenTextButton({
	buttonText,
	height,
	icon,
	jsx,
	onClick,
	text,
	...additionalProps
}) {
	const [show, setShow] = useState(false)
	const _onClick = () => {
		setShow(true)
		if (onClick) onClick()
	}

	const _buttonText = useT(buttonText)

	if (!text) return null

	const excerpt = `${text.slice(0, 3)} ...`
	const props = (show && additionalProps) || {}

	return (
		<Button
			icon={icon}
			style={{ borderRadius: 28, fontSize: 15, height: 50 }}
			teal
			{...props}
		>
			{show ? (
				jsx || text
			) : (
				<>
					{excerpt}
					<div className={mix([styles.button, styles.white])} onClick={_onClick} role="button">
						{_buttonText}
					</div>
				</>
			)}
		</Button>
	)
}

function HiddenEmail({ recipient }) {
	const email = recipient?.contact?.email_2
	const props = { href: `mailto:${email}` }

	return (
		<HiddenTextButton
			buttonText="Pokaż e-mail"
			icon="mail"
			text={email}
			{...props}
		/>
	)
}

function _HiddenNumbers (props) {
	const { offer, recipient } = props
	const phones = userPhone(recipient, false, offer)
	if (phones.length < 2) return <_HiddenNumber {...props} />

	return (
		<HiddenTextButton
			buttonText="Pokaż"
			icon="call"
			jsx={<div style={{color:'#fff',fontSize:16,lineHeight:1.8,marginLeft:12,paddingBlock:12,textAlign:'left'}}>{
				phones.map(phone => <a href={'tel:' + phone} key={phone} style={{display:'block'}}>{phone}</a>)}
			</div>}
			text={Array.isArray(phones) && phones.length ? phones[0] : 'Brak telefonu'}
			style={{height:'unset'}}
		/>
	)
}

function _HiddenNumber({ offer, recipient }) {
	const phone = offer?.tracking?.phone || userPhone(recipient)
	const onPhoneClick = useViewCallback(recipient, offer)
	const props = { href: `tel:${phone}` }

	return (
		<HiddenTextButton
			buttonText="Pokaż"
			icon="call"
			onClick={onPhoneClick}
			text={phone}
			{...props}
		/>
	)
}

function _WriteMessageButton({
	isLoggedIn,
	offer,
	recipient,
	user,
	...rest
}) {
	const [open, setOpen] = useState(false)
	const [redirect, setRedirect] = useRedirect()
	const isSameUser = user?._id === recipient?._id
	const t = useWriteButtonText()

	const openForm = useCallback(() => {
		if (isSameUser) return MessageHelper.message(`${t.swoje}.`)

		if (isLoggedIn) {
			setOpen(true)
		} else {
			setRedirect()
			redirect(LOGIN_PAGE)
		}
	}, [isSameUser, t, isLoggedIn, redirect, setRedirect])
	const closeForm = useCallback(() => setOpen(false), [])

	return (
		<>
			<Button {...rest} icon="mail_outline" onClick={openForm} blue>
				{t.napisz}
			</Button>
			<ContactForm
				from={user?._id}
				isLoggedIn={isLoggedIn}
				isOpen={open}
				offer={offer}
				onClose={closeForm}
				to={recipient?._id}
				topic={offerName(offer)}
				user={user}
			/>
		</>
	)
}

function _MobileSticky({ isLoggedIn, offer, recipient, user }) {
	const phone = offer?.tracking?.phone || userPhone(recipient)
	const call = useT('Zadzwoń')
	const onPhoneClick = useViewCallback(recipient, offer)

	return (
		<>
			<DisplaySticky
				initialHeight={94}
				style={{ background: 'var(--white)', paddingBlock: 18, borderTop:'1px solid var(--box-shadows)' }}
				bottom
			>
				<Container
					style={{
						display: 'flex',
						gap: 20,
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					{phone && (
						<Button
							href={`tel:${phone}`}
							icon="call"
							onClick={onPhoneClick}
							style={{ flexGrow: 1 }}
							teal
						>
							{call}
						</Button>
					)}
					<WriteMessageButton
						isLoggedIn={isLoggedIn}
						offer={offer}
						recipient={recipient}
						style={{ flexGrow: 1 }}
						user={user}
					/>
				</Container>
			</DisplaySticky>
		</>
	)
}

function useWriteButtonText() {
	return {
		swoje: useT('Nie można odpowiadać na własne ogłoszenie'),
		napisz: useT('Napisz'),
	}
}

const WriteMessageButton = withUser(_WriteMessageButton)
const HiddenNumber = withUser(_HiddenNumber)
const HiddenNumbers = withUser(_HiddenNumbers)
const MobileSticky = withUser(_MobileSticky)

DisplayContact.WriteMessageButton = WriteMessageButton
DisplayContact.HiddenEmail = HiddenEmail
DisplayContact.HiddenNumber = HiddenNumber
DisplayContact.MobileSticky = MobileSticky

export default DisplayContact
export {
	WriteMessageButton,
	HiddenEmail,
	HiddenNumber,
	HiddenNumbers,
	MobileSticky,
}
