export default function userAddress (user, offer) {
    const { address, dealer } = user || {}
    const { firm_address, firm_city } = dealer || {}
    const { tracking } = offer || {}
    const { address: trackingAddress } = tracking || {}

    if (!address) return ''

    return firm_address
        || address.full
        || [address.street, address.city, address.postcode].filter(Boolean).join(' ').trim()
        || trackingAddress
        || firm_city
}