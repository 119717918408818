import { useCallback } from 'react'
import { post } from '@api/apiFetcher'

export default function useViewCallback (user = {}, offer = {}) {
    return useCallback(() => {
        if (!user || !offer) return

        post({
            path: `/api/offers/${offer.type}/${offer._shortid}/hit_phone`,
            data: { user: user._id },
        })
        .then(_ => null)
        .catch(err => console.log(err))

    }, [user, offer])
}