import { useEffect } from 'react'
import useWindowScroll from '@hooks/useWindowScroll'

import styles from './DisplaySticky.module.css'

function DisplaySticky({
	children,
	initialHeight = 137,
	style,
	bottom,
}) {
	const [windowScroll] = useWindowScroll()
	const wouldBeTop = initialHeight - windowScroll
	const top = Math.max(wouldBeTop, 0)

	useEffect(() => {
		const bodys = document.getElementsByTagName('body')
		const body = bodys && bodys[0]
		if (body) {
			body.style.paddingBottom = '82px'
			return () => (body.style.paddingBottom = null)
		}
	}, [])

	return (
		<div
			className={styles.sticky}
			style={{
				...(style || {}),
				...(bottom ? { bottom: 0 } : { top }),
			}}
		>
			{children}
		</div>
	)
}

export default DisplaySticky
